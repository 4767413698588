$material-icons-font-path: "~material-icons/iconfont/";
@import "~material-icons/iconfont/material-icons.scss";
@import "./assets/scss/_common_styles.scss";
/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2)
    format("woff2");
}

.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

$font-map: null;

$fonts: (
  roboto: (
    font-regular: "Roboto Regular",
    font-light: "Roboto Light",
    font-medium: "Roboto Medium",
    font-bold: "Roboto Bold",
  ),
  openSans: (
    font-regular: "opensans-regular",
    font-light: "opensans-light",
    font-medium: "opensans-medium",
    font-bold: "opensans-bold",
  ),
  lato: (
    font-regular: "lato-regular",
    font-light: "lato-light",
    font-medium: "lato-medium",
    font-bold: "lato-bold",
  ),
  montserrat: (
    font-regular: "montserrat-regular",
    font-light: "montserrat-light",
    font-medium: "montserrat-medium",
    font-bold: "montserrat-bold",
  ),
  notoSans: (
    font-regular: "notosans-regular",
    font-light: "notosans-light",
    font-medium: "notosans-medium",
    font-bold: "notosans-bold",
  ),
);

@mixin fontFamily($fonts) {
  @each $font, $map in $fonts {
    .font-#{$font} & {
      $font-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($fonts, $font), "#{$key}");
        $font-map: map-merge(
          $font-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $font-map: null !global;
    }
  }
}

@function fonted($key) {
  @return map-get($font-map, $key);
}

html,
body {
  height: 100%;
  font-size: 62.5%;
}
body {
  margin: 0;
  @include fontFamily($fonts) {
    font-family: fonted(font-regular);
  }
  color: $text-on-bg !important;
}

.popover {
  display: block !important;
}

.ag-cell,
.ag-cell-not-inline-editing,
.ag-cell-with-height,
.ag-cell-focus {
  overflow: visible !important;
}

.ag-row {
  z-index: 0 !important;
}

.ag-row.ag-row-focus {
  z-index: 1 !important;
}

.fade {
  opacity: 1 !important;
}

.indicator-filter {
  .mat-option-pseudo-checkbox {
    order: 2;
  }
  .mat-option-text {
    order: 1;
  }
}

.my-full-screen-dialog {
  .mat-dialog-container {
    max-width: none;
    width: 100vw;
    height: 100vh;
  }
}
.submit-button {
  display: none;
}

.leaflet-popup-content-wrapper {
  background: #383838;
  border-radius: 5px;
  width: 344px;
  height: 220px;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #383838 !important;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

.custom-popup {
  .leaflet-popup-content-wrapper {
    .leaflet-popup-content {
      margin: 0;
      width: 100% !important;
      .map-popover-headding {
        background: rgb(71, 71, 71);
        padding: 15px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        p {
          margin: 0;
          padding: 0 !important;
          font-size: 20px;
          letter-spacing: 0.25px;
          color: $white;
          cursor: pointer;
        }
      }
      .map-popover-body {
        background: rgb(56, 56, 56);
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: $white;
        padding: 12px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        font-family: "Roboto Light", sans-serif;
        .comp-details {
          height: 5px;
          .pop-content {
            width: 120px;
          }
        }
        .exist-customer {
          font-size: 14px;
        }
      }
    }
  }
}

.leaflet-control-zoom {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  background: #444343;
  padding: 2px;
}

.mat-dialog-container {
  background: $surface !important;
  border-radius: $lg-comp-corner-radius !important;
  color: $text-on-surface;
}

.leaflet-touch .leaflet-bar a:first-child {
  color: #a8a4a4;
  border-left: 1px solid #757575;
  background-color: #444343;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.leaflet-touch .leaflet-bar a:last-child {
  color: #a8a4a4;
  background-color: #444343;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 55px;
  height: 35px;
}

.leaflet-bar a:first-child {
  width: 55px;
  height: 35px;
  border-left: 1px solid #757575;
  color: #a8a4a4;
  background-color: #444343;
  align-items: center;
  padding-top: 5px;
}

.leaflet-bar a:last-child {
  width: 55px;
  height: 35px;
  color: #a8a4a4;
  background-color: #444343;
  align-items: center;
  padding-top: 5px;
  // border-right: 1px solid #695b5b;
}

.leaflet-bar a:first-child {
  &:hover {
    border-bottom: none;
  }
}

.leaflet-touch .leaflet-bar a {
  width: 55px;
  height: 36px;
  line-height: 37px;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 0px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

.leaflet-bar a {
  background-color: #292828;
  border-bottom: none;
}

.ql-editor p {
  color: black;
}

.blue-snackbar {
  background-color: rgb(90, 88, 88);
}

.snackbar-danger {
  background-color: red !important;
}
.snackbar-success {
  background-color: green !important;
}
.snackbar-warning {
  background-color: yellow !important;
}

.c-pointer {
  cursor: pointer;
}

.mat-pseudo-checkbox.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox.mat-pseudo-checkbox-indeterminate {
  background: var(--primary) !important;
}

.mat-pseudo-checkbox {
  &:not(.mat-pseudo-checkbox-checked){
    color: $text-on-surface;
  }
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow,
.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.mat-option {
  color: var(--textOnSurface) !important;
}

.common-list {
  .ag-header-container .ag-header-cell[col-id="ConfidenceLevel"] {
    .ag-header-cell-text {
      white-space: pre-wrap;
      display: inline-flex;
      text-align: center;
      align-items: center;
    }
  }

  .ag-cell[col-id="riskIndicators"] {
    .ag-cell-wrapper,
    .ag-cell-value {
      height: 100%;
    }
  }

  .ag-row .ag-cell[col-id="ConfidenceLevel"] {
    text-align: right;
  }

  .bst_mat_input {
    width: 100%;
    line-height: 1.5;
  }

  .count {
    font-size: 15px !important;
    color: #fff !important;
    width: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block !important;
  }

  .ag-cell-value:not(:empty) {
    margin-left: 3px !important;
    width: 94%;
  }

  .mat-form-field-underline {
    display: none;
  }

  .ag-table-wrapper {
    app-custom-table-renderer {
      svg,
      span {
        fill: rgba(255, 255, 255, 0.32);
        color: rgba(255, 255, 255, 0.32);
        font-size: 20px;
        position: relative;
        cursor: pointer;
      }
    }
  }
}

.common-list {
  .custom-checkbox {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      border: 2px solid rgba(255, 255, 255, 0.4);
      border-radius: 4px;
    }

    &:checked + label:before {
      background: $primary-color;
      border-color: $primary-color;
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    &:checked + label:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 8px;
      background: rgba(0, 0, 0, 0.4);
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 rgba(0, 0, 0, 0.4), 4px 0 0 rgba(0, 0, 0, 0.4),
        4px -2px 0 rgba(0, 0, 0, 0.4), 4px -4px 0 rgba(0, 0, 0, 0.4),
        4px -6px 0 rgba(0, 0, 0, 0.4), 4px -8px 0 rgba(0, 0, 0, 0.4);
      transform: rotate(45deg);
    }
  }

  .mat-form-field-underline {
    bottom: 0;
  }

  .mat-select-value-text {
    white-space: nowrap;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120%;
    width: 90%;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120%;
      width: 100%;
    }
  }

  .mat-form-field-flex {
    border-radius: 0.5rem !important;
  }

  .ag-paging-row-summary-panel {
    background-color: $input-group;
    min-width: 89px;
    min-height: 40px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    span[ref="lbLastRowOnPage"] {
      margin-right: 5px;
      margin-left: 2px;
    }
    span[ref="lbFirstRowOnPage"] {
      margin-right: 5px;
      margin-left: 2px;
    }

    span[ref="lbRecordCount"] {
      margin-left: 5px;
    }
  }
}

app-dashboard-table-view {
  .common-list {
    .ag-table-wrapper {
      .common-table {
        .ag-root {
          &.ag-layout-normal {
            overflow: visible;
          }
        }
      }
    }
  }
}

.d-flex {
  display: flex !important;
}
.fd-c {
  flex-direction: column;
}

.right-panel-w {
  width: calc(100% - 330px) !important;
}

.version {
  bottom: 0;
  font-size: 12px;
  padding: 10px;
  color: $text-on-surface;
}

.ng-tooltip {
  position: absolute;
  max-width: auto;
  max-height: auto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 10px;
  background: $surface-color2;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0.9;
  white-space: nowrap;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-color: $surface-color2 transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-color: transparent transparent $surface-color2 transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -8px;
  border-width: 8px;
  border-color: transparent transparent transparent $surface-color2;
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -8px;
  border-width: 8px;
  border-color: transparent $surface-color2 transparent transparent;
}

.ng-tooltip-show {
  opacity: 0.9;
  box-shadow: $hover-shodow;
}

.fnt-12 {
  font-size: 12px;
}

.clr-p6 {
  color: rgba($white, 0.6);
}

.mat-autocomplete-panel,
.mat-expansion-panel {
  background: $surface !important;
  color: $text-on-surface !important;
}
.btn-primary {

  color           : $text-on-primary;
  background-color: $primary-color;
  border-color    : $primary-color;

  &:hover,
  &:focus,
  &:active {
    color           : $text-on-primary;
    background-color: $primary-color;
    border-color    : $primary-color;
  }
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: rgba(255,255,255,0.12);
}

.mat-expansion-indicator {
  &::after {
    color: $text-on-surface;
  }
}

.mat-datepicker-content,
.mat-menu-panel {
  border-radius: $sm-comp-corner-radius !important;
}

.ag-center-cols-container {
  background-color: $surface;
}

.document-status-filed{
  .mat-form-field{
    border-radius: 10px 10px 0 0;
    height: 53px;
    .mat-form-field-wrapper{
      height: 100%;
      padding-bottom: 0;
    }
    .mat-form-field-flex{
      background-color: transparent;
      height: 100%;
     }
     .mat-form-field-infix{
      display: flex;
      align-items: center;
      height: 100%;
      border: none;
     }
     .mat-select-value-text{
      font-size: 14px;
     }
     .mat-select-arrow-wrapper{
      transform: unset;
     }
  }
}

.f-16{
  font-size: 16px;
}
