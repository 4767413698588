.custom-card {
  @include padding(15px);
  background-color: $surface;
  border: 0;
  border-radius: $lg-comp-corner-radius;
  position: relative;
  .card-header {
    background-color: transparent;
    border: 0;
    position: relative;
    padding: 0;
    margin: 16px 0;

    i,
    span,
    svg {
      color: $text-on-surface;
    }

    button {
      @extend .bg-transparent;
    }

    h3 {
      @extend .mar-b0;
      @extend .roboto-light;
      @extend .text-unset;
      font-size: 1.8rem;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: -0.3px;
      color: $text-on-surface;
    }

    &:after {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(to left, rgba(249, 249, 249, 0.2), rgba(250, 250, 250, 0.5));
    }
  }

  .card-body {
    @extend .text-cream;
    min-height: 10rem;
    position: relative;
    padding: 10px 0 10px;
    position: relative;
  }

  &.sm-cards {
    .card-header {
      font-size: 1.4rem;

      &:after {
        opacity: 0;
      }
    }
  }
}

@media (min-width: $screen-lg) {
}

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
}

@media (min-width: $screen-xxs) and (max-width: $screen-xs-max) {
}

@media (min-width: $screen-xxs) and (max-width: $screen-xs) {
}
