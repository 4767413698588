.bst_element {
  font-size: 62.4%;
}

button {
  color: $text-on-surface;
  border: 0;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

span {
  display: inline-block;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.border-0 {
  border: 0 !important;
}
.margin-0 {
  margin: 0 !important;
}
.padding-0 {
  padding: 0 !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-align-r {
  text-align: right;
}
.z-99 {
  z-index: 99;
}

@for $i from 1 through 40 {
  .f-#{$i} {
    font-size: ($i * 0.1rem) !important;
  }
}

@for $i from 1 through 5 {
  .pad-#{$i * 5} {
    padding: ($i * 5px) !important;
  }
  .pad-t#{$i * 5} {
    padding-top: ($i * 5px) !important;
  }
  .pad-r#{$i * 5} {
    padding-right: ($i * 5px) !important;
  }
  .pad-b#{$i * 5} {
    padding-bottom: ($i * 5px) !important;
  }
  .pad-l#{$i * 5} {
    padding-left: ($i * 5px) !important;
  }
  .pad-x#{$i * 5} {
    padding-left: ($i * 5px) !important;
    padding-right: ($i * 5px) !important;
  }
  .pad-y#{$i * 5} {
    padding-top: ($i * 5px) !important;
    padding-bottom: ($i * 5px) !important;
  }
}

@for $i from 1 through 5 {
  .m-#{$i * 5} {
    margin: ($i * 5px) !important;
  }
  .m-t#{$i * 5} {
    margin-top: ($i * 5px) !important;
  }
  .m-r#{$i * 5} {
    margin-right: ($i * 5px) !important;
  }
  .m-b#{$i * 5} {
    margin-bottom: ($i * 5px) !important;
  }
  .m-l#{$i * 5} {
    margin-left: ($i * 5px) !important;
  }
}

html {
  height: 100%;
}

body {
  background-color: $bg-color !important;
  color: var(--textOnSurface) !important;
  font-family: $roboto-regular;
  font-size: $font-size-small;
  line-height: ($line-height-computed * 0.7);
  letter-spacing: 0.5px;
  height: auto;
  min-height: 100%;
  padding: 118px 0 0 !important;
  overflow-x: hidden !important;
  &.modal-open {
    overflow: hidden !important;
    &.modal-visible {
      overflow-y: auto !important;
    }
  }
}

.right-panel-alignment mat-dialog-container::-webkit-scrollbar,
.custom-scroll-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.right-panel-alignment mat-dialog-container::-webkit-scrollbar-track,
.custom-scroll-wrapper::-webkit-scrollbar-track {
  background: rgba(26, 39, 52, 0.2);
}

.right-panel-alignment mat-dialog-container::-webkit-scrollbar-track,
.custom-scroll-wrapper:hover::-webkit-scrollbar-track {
  background: rgba(26, 39, 52, 0.8);
}

.right-panel-alignment mat-dialog-container::-webkit-scrollbar-thumb,
.custom-scroll-wrapper::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(169, 169, 169, 0.3);
}

.right-panel-alignment mat-dialog-container::-webkit-scrollbar-thumb,
.custom-scroll-wrapper:hover::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.8);
}

.custom-scroll-wrapper:hover::-webkit-scrollbar-corner {
  background: rgba(26, 39, 52, 0.8);
}

.custom-scroll-wrapper::-webkit-scrollbar-corner {
  background: rgba(26, 39, 52, 0.2);
}

.custom-scroll-wrapper {
  transition: all 0.3s ease-in-out;
  &:focus {
    outline: none;
  }
}

.border-style {
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-color: $text-on-surface;
    border-style: solid;
    opacity: 0.12;
  }
}

.card-wrapper {
  background: $cardBackground;
  box-shadow: $default-shodow;
  border-radius: 1rem;
  padding: 0 2.4rem;
  .cardHeader {
    color: $color-white;
    padding: 1.6rem 0;
    @extend .border-style;
    &::after {
      border-width: 0;
    }
    .card-header {
      color: $color-white;
    }
    .close-btn {
      span {
        color: $color-white;
      }
    }
  }
  .card-body {
    padding: 1.6rem 0;
    .card-text {
      font-size: 14px;
      line-height: 2;
      letter-spacing: -0.5px;
      color: $color-white;
    }
  }
}

.modal-button {
  padding: 0.65rem 1.524rem 0.65rem 1.524rem;
  margin: 0 0 0 0.8rem;
  border: solid 1px $modal-btn-color;
  background: transparent;
  color: $modal-btn-color;
  border-radius: 0.4rem;
  font-size: 14px;
  letter-spacing: 1.25px;
  box-shadow: $button-shadow;
  &.active,
  &:hover {
    background: $modal-btn-color;
    color: $color-white;
  }
  &.disabled-button {
    border: solid 1px $textOnSurfaceLight;
    color: $textOnSurface;
    opacity: 0.65;
    cursor: no-drop;
    &.active,
    &:hover {
      background: transparent;
      color: $textOnSurface;
      opacity: 0.65;
    }
  }
}

.modal-search {
  margin: null null 0.4rem null;
  min-width: 25rem;

  .modal-input {
    background: $surfaceBg;
    padding: 1.1rem 2.4rem 1.1rem 5.2rem;
    border-radius: 0.4rem;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.5px;
    color: $inputField;
    outline: none;
    &::placeholder {
      color: $inputField;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.5px;
    }
  }

  .modal-serch-icon {
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    font-size: 1.7rem;
    color: $surface;
    span {
      margin-top: 0.5rem;
    }
  }
}

.transparent {
  background: transparent;
}

.cursor {
  cursor: pointer;
}

.entities-editer-options {
  padding: 0.5rem 1.6rem 0.5rem 0;
  border-right: 1px solid $modal-border-color;
  .entitity-tool,
  .source,
  .sourceByCountry,
  .dataModel,
  .operation {
    margin-left: 0.8rem;
    .entitity-tool-wrapper,
    .source-wrapper,
    .sourceByCountry-wrapper,
    .dataModel-wrapper,
    .operation-wrapper {
      width: 3.6rem;
      height: 3.6rem;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 50%;
      margin: 0 auto;
      &.screen-wrapper,
      &.delete-wrapper,
      &.attribute-wrapper {
        border-radius: 0.6rem;
      }
      &.attribute-wrapper {
        transform: rotate(45deg);
        .align-icon {
          transform: rotate(-45deg);
          padding-top: 3.5rem;
        }
      }
      .entitity-tool-icon,
      .source-icon,
      .sourceByCountry-icon,
      .dataModel-icon,
      .operation-icon {
        width: 2.4rem;
        height: 2.4rem;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        line-height: 1.9rem;
      }
      .entitity-tool-add,
      .add-icon {
        width: 1rem;
        height: 1rem;
        right: 0.5rem;
        bottom: 1rem;
      }
      .entitity-tool-icon {
        margin-top: 0.2rem;
        color: $blue;
        &.folder {
          color: $teal-color;
        }
        &.schema {
          color: $textOnSurface;
        }
        &.screen {
          color: $accent;
        }
      }
    }
    .entitity-tool-text,
    .nodeTitle-text {
      font-size: 1.2rem;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: $color-white;
      margin-top: 0.6rem;
      width: 6rem;
    }
  }
}

.text-on-surface {
  color: $text-on-surface !important;
}

.title-text-on-surface {
  color: $title-text-on-surface !important;
}

.text-on-bg {
  color: $text-on-bg !important;
}

.bst-dropdown-wrapper {
  max-width: 136px;

  &.show {
    .dropdown-toggle::after {
      transform: rotateX(180deg) !important;
    }
  }

  button {
    color: $text-on-surface !important;
    border-color: none;
    border: 0;
    font-size: 1.6rem;
    box-shadow: none !important;

    &.custom-btn {
      background-color: $input-color-bg !important;
      color       : $text-on-surface !important;
    }
    &:hover {
      color: $text-on-surface;
    }
  }

  .dropdown-menu {
    background-color: $surface;
    max-width: 100%;
  }

  &.table-view-dropdown {
    min-width: 20.5rem;

    button {
      width: 100%;
      border-radius: $sm-comp-corner-radius !important;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btn-add-table {
      justify-content: center;
      width: 90%;
      margin: 0 auto;
      background-color: rgba(251, 251, 251, 0.2);
    }

    .dropdown-menu {
      width: 100%;

      .dropdown-control-wrapper {
        cursor: pointer;
        padding: 5px 10px;

        &:hover {
          background: rgba(249, 249, 249, 0.2);
        }

        &:nth-last-of-type(1) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.roboto-light {
  font-family: $roboto-light !important;
  font-style: normal;
  font-stretch: normal;
}

.bar-text {
  font-size: 12px !important;
}

.tooltip-right {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 10px;
  background: #383838;
  border-radius: 4px;
  z-index: 1000;
  font-size: 12px !important;
  pointer-events: none;
  visibility: hidden;
  max-width: 250px;
  position: absolute;
  overflow: visible;
}

.tooltip-right:before {
  transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  position: absolute;
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  top: auto;
  bottom: -8px;
  left: 50%;
  border-top: 8px solid #383838;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 0;
  z-index: 9;
}

.alert {
  .close {
    color: $color-white;
  }
  .alert-text {
    font-size: 1.4rem;
    padding: 2rem;
    color: $text-on-surface;
  }
}

.alert-success {
  background: rgba($teal-color, 0.2);
  border-color: rgba($teal-color, 0.2);
}

.alert-danger {
  background: rgba($red-color, 0.2);
  border-color: rgba($red-color, 0.2);
}

.alert-info {
}

.alert-warning {
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $primary-color;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $primary-color;
}

.display-flex {
  display: flex !important;
}

.flex-veritical-align {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
}

.line-height-0 {
  line-height: 0 !important;
}
.right-panel-alignment {
  position: absolute !important;
  top: 52px;
  right: 0;
  width: max-content !important;
  height: calc(100vh - 52px);
}

::ng-deep .document-detail-panel-open {
  .right-panel-alignment {
    height: calc(100vh - 115px) !important;

    .bottom-fixed-button {
      bottom: 63px !important;
    }
  }
}
.right-panel-scrolled {
  height: 100vh !important;
  top: 0 !important;
  height: 100vh;

  mat-dialog-container {
    overflow: auto !important;
    overflow-x: hidden !important;
  }
}

.toast-success {
  background-color: #00796b !important;
  opacity: 0.95 !important;
}

.toast-error {
  background-color: #ef5350 !important;
  opacity: 0.95 !important;
}

.toast-warning {
  background-color: #f26c0e !important;
  opacity: 0.95 !important;
}

.toast-container {
  top: 65px !important;
  width: 580px !important;
  pointer-events: auto !important;
}
.notification-toastr {
  padding: 15px !important;
  box-shadow: none !important;
  margin: 0 0 10px !important;
  width: 100% !important;
  background-image: none !important;
  border-radius: 4px;

  .upload-fail {
    font-size: 14px;
  }

  .uploaded {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 24px;

    svg {
      height: 20px;
      width: 20px;
      opacity: 0.7;
      margin-right: 10px;
    }
  }

  .delete-document {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 24px;

    svg {
      height: 20px;
      width: 20px;
      opacity: 0.7;
      margin-right: 20px;
      margin-left: 16px;
    }
  }
  .reason {
    padding-left: 6%;
    font-size: 12px;
  }
  .toast-close-button {
    opacity: 0.5 !important;
    font-weight: 100;
    font-size: 26px;
    top: 0.1em;
    right: 0.4em;
    text-shadow: none;
    z-index: 2;
  }
  .check-report,
  .reference {
    font-size: 14px !important;

    &:hover {
      cursor: pointer;
    }
  }

  .reference {
    text-decoration: underline !important;
  }
  .toastr-btn {
    position: relative;
    z-index: 1;
  }

  .preview {
    font-size: 14px;
    padding-top: 5px;
  }

  .upload-success {
    padding-left: 9%;
  }
}

.delete-modal-background-blur {
  -webkit-backdrop-filter: blur(10.9px);
  backdrop-filter: blur(10.9px);
  background-color: rgba($modal-bg, 0.6);
}
.delete-poupup {
  .mat-dialog-container {
    width: 664px;
    height: 201px;
    padding: 16px 32px 32px;
    border-radius: 10px;
    overflow: hidden;
  }
}
.document-deleted-checkbox {
  display: flex;
  align-items: center;
  margin-left: 37px;
  .mat-checkbox-ripple {
    display: none !important;
  }
  .mat-checkbox-label {
    font-size: 14px;
  }
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $primary-color !important;
  }
  .mat-checkbox-layout {
    margin-bottom: 0 !important;
  }
}
.deleted-document {
  .ag-cell:not(.badges-column) {
    opacity: 0.2 !important;
    pointer-events: none;
  }
}

.tile-dropdwon {
  background-color: $surface !important;
  padding: 0 8px !important;

  min-width: 180px !important;

  .light-white {
    color: rgba($white, 0.54);
  }
  .text-light {
    color: rgba($white, 0.87);
  }

  .card__download-option, .card__delete-option, .card__dropdown-file-icon{
    color: $text-on-surface !important;
  }

  button {
    padding: 0 24px !important;
  }
}

/*
================================================================================
**********************  Media Styles For The Site  ******************
================================================================================
*/

@media screen and (min-width: 1920px) {
  .bst_element {
    font-size: calc(var(--rootFontSize) + 6px);
  }
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .bst_element {
    font-size: calc(var(--rootFontSize) + 4px);
  }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .bst_element {
    font-size: calc(var(--rootFontSize) + 2px);
  }
}

@media screen and (min-width: $screen-lg) and (max-width: 1401px) {
  .bst_element {
    font-size: $root-font-size;
  }
}

@media (min-width: $screen-llg) {
  .bst_element {
    font-size: calc(var(--rootFontSize) + 2px);
  }
}

@media (min-width: $screen-lg) {
  .bst_element {
    font-size: $root-font-size;
  }
}

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
  .bst_element {
    font-size: $root-font-size;
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  .bst_element {
    font-size: calc(var(--rootFontSize) - 2px);
  }
}

@media (min-width: $screen-xxs) and (max-width: $screen-xs-max) {
  .bst_element {
    font-size: calc(var(--rootFontSize) - 5px);
  }
}

@media (min-width: $screen-xxs) and (max-width: $screen-xs) {
  .bst_element {
    font-size: calc(var(--rootFontSize) - 5px);
  }
}

::ng-deep .multi-select-wrapper {
  .mat-select-search-inner {
    padding: 16px;
    border-bottom: none !important;
  }
  .mat-select-search-input {
    background: rgba($white, 0.1);
    height: 40px;
    border-radius: 4px;
    caret-color: $white !important;
  }
  .multi-select-all {
    position: relative;
  }
  .mt-32 {
    margin-top: 32px;
  }
  .mat-select-search-panel {
    padding-bottom: 0;
  }
  .optiion-wrapper {
    max-height: 145px;
    overflow-y: scroll;
  }
  .mat-select-search-clear {
    right: 18px !important;
    top: 14px !important;
    .mat-ripple {
      display: none;
    }
  }
}

::ng-deep {
  .remove-empty {
    opacity: 0;
    pointer-events: none;
  }
  .ag-center-cols-clipper {
    background-color: $bg-header-new !important;
  }

  .ag-overlay-loading-wrapper {
    height: calc(100% - 10.5rem) !important;
    margin-top: auto !important;
    background: $surface !important;
    color: $text-on-surface !important;
  }
  .grid-border-bottom {
    border-bottom: 1px solid $small-componnets-item-bg !important;
  }
}

.flex-wrap-none {
  flex-wrap: unset !important;
}
.mw-100 {
  min-width: 100% !important;
}
.mw-50 {
  min-width: 50% !important;
}

::ng-deep .reference-form-filed {
  height: 40px;
  margin-top: 16px;
  width: 100%;
  padding: 0 !important;

  .mat-form-field-wrapper,
  .mat-form-field-flex {
    height: 100%;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .mat-form-field-flex {
    background: transparent;
  }
  .mat-form-field-infix {
    height: 100%;
    border-top: none !important;
    padding: 0 8px !important;

    input {
      height: 100%;
      font-size: 16px;
      caret-color: $text-on-surface !important;
      padding: 0 0 0 30px;
      width: calc(96% - 45px);
      transform: translateY(1px);

      &::placeholder {
        font-size: 16px;
        color: $text-on-surface;
      }
    }
  }
}

.add-table-alert-popover {
  background-color: $surface;
  color: $text-on-surface;
  border-radius: $sm-comp-corner-radius;
}

.bst_input_group {
  .custom-input {
    background: rgba(255,255,255,.12);
    border: none;
    color: $text-on-surface;
  }
}

.bs-popover-bottom>.arrow::after {
  border-bottom-color: $surface;
}

.expired-document {
  .ag-cell:not(.badges-column ,.expiry-column ,.id-column,.name-column) {
    opacity: 0.2 !important;
    pointer-events: none;
          }
    }
   .expiory-text-color{
    color:$expired-text-color;
}

.warning-text-color{
  color: $orange-color
}

.dropdown-menu {
  border-radius: $sm-comp-corner-radius;
}

.mat-select-panel {
  border-radius: $sm-comp-corner-radius !important;
}

::ng-deep .ag-floating-filter-button{
  display: none !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.pr-5{
  padding-right: 5px !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
