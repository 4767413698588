@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin center-position() {
  @include translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
@mixin background-size($bg-size) {
  -webkit-background-size: $bg-size;
  -moz-background-size: $bg-size;
  background-size: $bg-size;
}
@mixin padding($padding) {
  padding: $padding;
}
@mixin padding-left($padding) {
  padding-left: $padding;
}
@mixin padding-right($padding) {
  padding-right: $padding;
}
@mixin padding-top($padding) {
  padding-top: $padding;
}
@mixin padding-bottom($padding) {
  padding-bottom: $padding;
}
@mixin margin($margin) {
  margin: $margin;
}
@mixin margin-left($margin) {
  margin-left: $margin;
}
@mixin margin-right($margin) {
  margin-right: $margin;
}
@mixin margin-top($margin) {
  margin-top: $margin;
}
@mixin margin-bottom($margin) {
  margin-bottom: $margin;
}
@mixin circle($circle) {
  @include border-radius($circle);
  width: $circle;
  height: $circle;
  line-height: $circle;
  vertical-align: middle;
  text-align: center;
}
@mixin translate($x, $y) {
  transform: translate($x, $y);
  -o-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -webkit-transform: translate($x, $y);
}
@mixin translateX($x) {
  transform: translateX($x);
  -o-transform: translateX($x);
  -moz-transform: translateX($x);
  -webkit-transform: translateX($x);
}
@mixin translateY($y) {
  transform: translateY($y);
  -o-transform: translateY($y);
  -moz-transform: translateY($y);
  -webkit-transform: translateY($y);
}
@mixin box-shadow($b) {
  -webkit-box-shadow: $b;
  -moz-box-shadow: $b;
  box-shadow: $b;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
  animation-name: $name;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
  animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
  animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
  animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
  animation-fill-mode: $fill-mode;
}

@mixin backface-visibility($visibility) {
  -webkit-backface-visibility: $visibility;
  -moz-backface-visibility: $visibility;
  backface-visibility: $visibility;
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
  -moz-box-sizing: $boxmodel;
  box-sizing: $boxmodel;
}

@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
  -moz-column-count: $column-count;
  column-count: $column-count;
  -webkit-column-gap: $column-gap;
  -moz-column-gap: $column-gap;
  column-gap: $column-gap;
}

@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
  -moz-hyphens: $mode;
  -ms-hyphens: $mode;
  -o-hyphens: $mode;
  hyphens: $mode;
}

@mixin scale($ratio...) {
  -webkit-transform: scale($ratio);
  -ms-transform: scale($ratio);
  -o-transform: scale($ratio);
  transform: scale($ratio);
}

@mixin scaleX($ratio) {
  -webkit-transform: scaleX($ratio);
  -ms-transform: scaleX($ratio);
  -o-transform: scaleX($ratio);
  transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
  -webkit-transform: scaleY($ratio);
  -ms-transform: scaleY($ratio);
  -o-transform: scaleY($ratio);
  transform: scaleY($ratio);
}
@mixin skew($x, $y) {
  -webkit-transform: skewX($x) skewY($y);
  -ms-transform: skewX($x) skewY($y);
  -o-transform: skewX($x) skewY($y);
  transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}
@mixin rotateX($degrees) {
  -webkit-transform: rotateX($degrees);
  -ms-transform: rotateX($degrees);
  -o-transform: rotateX($degrees);
  transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
  -webkit-transform: rotateY($degrees);
  -ms-transform: rotateY($degrees);
  -o-transform: rotateY($degrees);
  transform: rotateY($degrees);
}
@mixin perspective($perspective) {
  -webkit-perspective: $perspective;
  -moz-perspective: $perspective;
  perspective: $perspective;
}
@mixin perspective-origin($perspective) {
  -webkit-perspective-origin: $perspective;
  -moz-perspective-origin: $perspective;
  perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
  transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
  transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
  -webkit-transition-timing-function: $timing-function;
  transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform $transition;
}

@mixin user-select($select) {
  -webkit-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select;
  user-select: $select;
}

$fonts: (
  roboto: (
    font-regular: "Roboto Regular",
    font-light: "Roboto Light",
    font-medium: "Roboto Medium",
    font-bold: "Roboto Bold",
  ),
  openSans: (
    font-regular: "opensans-regular",
    font-light: "opensans-light",
    font-medium: "opensans-medium",
    font-bold: "opensans-bold",
  ),
  lato: (
    font-regular: "lato-regular",
    font-light: "lato-light",
    font-medium: "lato-medium",
    font-bold: "lato-bold",
  ),
  montserrat: (
    font-regular: "montserrat-regular",
    font-light: "montserrat-light",
    font-medium: "montserrat-medium",
    font-bold: "montserrat-bold",
  ),
  notoSans: (
    font-regular: "notosans-regular",
    font-light: "notosans-light",
    font-medium: "notosans-medium",
    font-bold: "notosans-bold",
  ),
);
/*
* Implementation of fonts
*/
@mixin fontFamily($fonts) {
  @each $font, $map in $fonts {
    .font-#{$font} & {
      $font-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($fonts, $font), "#{$key}");
        $font-map: map-merge(
          $font-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $font-map: null !global;
    }
  }
}

@function fonted($key) {
  @return map-get($font-map, $key);
}
